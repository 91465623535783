import { Search } from '@mui/icons-material';
import { Box, Button, FormGroup, Paper, Table, TableBody, TableCell, TableContainer, TableFooter, TableHead, TableRow, ThemeProvider, ToggleButton, ToggleButtonGroup, Tooltip, Typography, createTheme } from '@mui/material';
import { addDays, format, subDays } from 'date-fns';
import { useMemo, useState } from 'react';
import LogitarApi, { APIPath } from '../api/LogitarApi';
import Config from '../config/Config';
import { DatePick } from '../misc/DatePick';
import { TipButton, getPayTimeframe, getTime } from '../misc/InternalFeatures';
import LogiTarUser from '../misc/User';
import { contentBoxStyle } from '../styles/styles';

const allowance = ["Ei", "Puoli", "Koko"]
const banks = ["", "Pidetty", "Korvataan"]

//Different branches have different headers, could be filtered but this keeps branch specific changes much more accessible
const headersKonnekuljetus = [
    { header: "Pvm", name: "date", get: (val) => format(new Date(val), "dd.MM.yyyy") },
    { header: "Tulospaikka", name: "costCentre" },
    { header: "Työ alkoi", name: "startTime", get: (val) => format(new Date(val), "HH:mm") },
    { header: "Työ päättyi", name: "endTime", get: (val) => val ? format(new Date(val), "HH:mm") : "" },
    { header: "Tunnit", name: "workHours", get: (val) => getTime(val) },
    { header: "Iltatyö", name: "eveningHours", get: (val) => getTime(val) },
    { header: "Yötyö", name: "nightHours", get: (val) => getTime(val) },
    { header: "Tauko", name: "breakHours", get: (val) => getTime(val) },
    { header: "Odotus", name: "waitHours", get: (val) => getTime(val) },
    { header: "Pekkanen", name: "shortTime", get: (val) => getTime(val) },
    { header: "Sairas", name: "sickHours", get: (val) => getTime(val) },
    { header: "Loma", name: "vacationHours", get: (val) => getTime(val) },
    { header: "Vanh. Vapaa", name: "paternityLeaveHours", get: (val) => getTime(val) },
    { header: "Pyhälisä", name: "holidayHours", get: (val) => getTime(val) },
    { header: "Päiväraha", name: "dailyAllowance", get: (val) => allowance[val] },
    { header: "Lisätieto", name: "route" }
];

const headersMetsanen = [
    { header: "Pvm", name: "date", get: (val) => format(new Date(val), "dd.MM.yyyy") },
    { header: "Tulospaikka", name: "costCentre" },
    { header: "Työ alkoi", name: "startTime", get: (val) => format(new Date(val), "HH:mm") },
    { header: "Työ päättyi", name: "endTime", get: (val) => val ? format(new Date(val), "HH:mm") : "" },
    { header: "Tunnit", name: "workHours", get: (val) => getTime(val) },
    { header: "Tauko", name: "breakHours", get: (val) => getTime(val) },
    { header: "Odotus", name: "waitHours", get: (val) => getTime(val) },
    { header: "Pekkanen", name: "shortTime", get: (val) => getTime(val) },
    { header: "Sairas", name: "sickHours", get: (val) => getTime(val) },
    { header: "Loma", name: "vacationHours", get: (val) => getTime(val) },
    { header: "Vanh. Vapaa", name: "paternityLeaveHours", get: (val) => getTime(val) },
    { header: "Pyhälisä", name: "holidayHours", get: (val) => getTime(val) },
    { header: "Sunnuntaitunnit", name: "sundayHours", get: (val) => getTime(val) },
    { header: "Työaikapankki", name: "fromBank", get: (val) => getTime(val) },
    { header: "Työaikapankki maksu", name: "bankMode", get: (val) => banks[val] },
    { header: "Päiväraha", name: "dailyAllowance", get: (val) => allowance[val] },
    { header: "Lisätieto", name: "route" }
]

const headersTarkkala = [
    { header: "Pvm", name: "date", get: (val) => format(new Date(val), "dd.MM.yyyy") },
    { header: "Tulospaikka", name: "costCentre" },
    { header: "Työ alkoi", name: "startTime", get: (val) => format(new Date(val), "HH:mm") },
    { header: "Työ päättyi", name: "endTime", get: (val) => val ? format(new Date(val), "HH:mm") : "" },
    { header: "Tunnit", name: "workHours", get: (val) => getTime(val) },
    { header: "Iltatyö", name: "eveningHours", get: (val) => getTime(val) },
    { header: "Yötyö", name: "nightHours", get: (val) => getTime(val) },
    { header: "Tauko", name: "breakHours", get: (val) => getTime(val) },
    { header: "Odotus", name: "waitHours", get: (val) => getTime(val) },
    { header: "Pekkanen", name: "shortTime", get: (val) => getTime(val) },
    { header: "Pyhälisä", name: "holidayHours", get: (val) => getTime(val) },
    { header: "Sunnuntaitunnit", name: "sundayHours", get: (val) => getTime(val) },
    { header: "Päiväraha", name: "dailyAllowance", get: (val) => allowance[val] },
    { header: "Lisätieto", name: "route" }
];

const headersAll = {
    "konnekuljetus": headersKonnekuljetus,
    "tarkkala": headersTarkkala,
    "softrain": headersTarkkala,
    "metsanen": headersMetsanen
}

const costCentreHeadersMetsanen = [
    { header: "Tulospaikka", name: "costCentre" },
    { header: "Tunnit", name: "workHours", get: (val) => getTime(val) },
    { header: "Tauko", name: "breakHours", get: (val) => getTime(val) },
    { header: "Odotus", name: "waitHours", get: (val) => getTime(val) },
    { header: "Pekkanen", name: "shortTime", get: (val) => getTime(val) },
    { header: "Pyhälisä", name: "holidayHours", get: (val) => getTime(val) },
    { header: "Sairas", name: "sickHours", get: (val) => getTime(val) },
    { header: "Loma", name: "vacationHours", get: (val) => getTime(val) },
    { header: "Vanh. Vapaa", name: "paternityLeaveHours", get: (val) => getTime(val) },
    { header: "Sunnuntaitunnit", name: "sundayHours", get: (val) => getTime(val) },
    { header: "Työaikapankki", name: "fromBank", get: (val) => getTime(val) },
    { header: "Aikapankki pidetty", name: "bankKeep" },
    { header: "Aikapankki korvataan", name: "bankUse" },
]

const costCentreHeadersAll = {
    "konnekuljetus": headersKonnekuljetus,
    "tarkkala": headersTarkkala,
    "softrain": headersTarkkala,
    "metsanen": costCentreHeadersMetsanen
}

const payheadersKonnekuljetus = [
    { field: "workHours", header: "Peruspalkka" },
    { field: "eveningHours", header: "Iltalisä" },
    { field: "nightHours", header: "Yölisä" },
    { field: "shortTime", header: "Pekkanen" },
    { field: "sickHours", header: "Sairas" },
    { field: "holidayHours", header: "Pyhälisä" },
    { field: "overworktotal", header: "Ylityön perusosa" },
    { field: "overworkhalf", header: "Ylityö 50%" },
    { field: "overworkfull", header: "Ylityö 100%" },
    { field: "dailyAllowanceHalf", header: "Puolipäiväraha", get: (val) => `${val} pv` },
    { field: "dailyAllowance", header: "Kokopäiväraha", get: (val) => `${val} pv` }
]
const payheadersMetsanen = [
    { field: "workHours", header: "Peruspalkka" },
    { field: "shortTime", header: "Pekkanen" },
    { field: "sickHours", header: "Sairas" },
    { field: "holidayHours", header: "Pyhälisä" },
    { field: "sundayHours", header: "Sunnuntaitunnit" },
    { field: "overworkhalf", header: "Ylityön perusosa" },
    { field: "toBank", header: "Työaikapankkiin"},
    { field: "dailyAllowanceHalf", header: "Puolipäiväraha", get: (val) => `${val} pv` },
    { field: "dailyAllowance", header: "Kokopäiväraha", get: (val) => `${val} pv` }
]

const payheadersTarkkala = [
    { field: "workHours", header: "Peruspalkka" },
    { field: "eveningHours", header: "Iltalisä" },
    { field: "nightHours", header: "Yölisä" },
    { field: "shortTime", header: "Pekkanen" },
    { field: "holidayHours", header: "Pyhälisä" },
    { field: "sundayHours", header: "Sunnuntaitunnit" },
    { field: "overworktotal", header: "Ylityön perusosa" },
    { field: "overworkhalf", header: "Ylityö 50%" },
    { field: "overworkfull", header: "Ylityö 100%" },
    { field: "dailyAllowanceHalf", header: "Puolipäiväraha", get: (val) => `${val} pv` },
    { field: "dailyAllowance", header: "Kokopäiväraha", get: (val) => `${val} pv` }
]

const payheadersAll = {
    "konnekuljetus": payheadersKonnekuljetus,
    "tarkkala": payheadersTarkkala,
    "softrain": payheadersTarkkala,
    "metsanen": payheadersMetsanen
}

const step = 14

const tableTheme = createTheme({
    components: {
        MuiFormGroup: {
            styleOverrides: {
                root: {
                    marginBottom: 4
                }
            }
        },
        MuiPaper: {
            styleOverrides: {
                root: {
                    padding: 8,

                }
            }
        },
        MuiTypography: {
            styleOverrides: {
                root: {
                    marginBottom: 4
                }
            }
        },
        MuiTableCell: {
            styleOverrides: {
                root: {
                    textAlign: "left",
                    padding: 4
                }
            }
        },
    }
})

const enableSalaryAndHolidayFiles = Config.getBranch() === "konnekuljetus" || Config.getBranch() === "softrain";

function calculateUserTotal(workhours) {

    const totals = {
        workHours: 0,
        eveningHours: 0,
        nightHours: 0,
        breakHours: 0,
        waitHours: 0,
        shortTime: 0,
        sickHours: 0,
        vacationHours: 0,
        paternityLeaveHours: 0,
        holidayHours: 0,
        sundayHours: 0,
        fromBank: 0,
        //dailyAllowance: 0
    }

    workhours.forEach((row) => {
        Object.keys(row).forEach((field) => {
            if (Object.keys(totals).includes(field)) {
                totals[field] += row[field]
            }
        })
    })

    // Subtract breaks and waiting time from workhours to match csv export
    totals.workHours = totals.workHours - totals.breakHours - totals.waitHours

    //Remove branch specific fields from totals if they don't exist on branch headers
    Object.keys(totals).forEach((field) => {
        if (!headersAll[Config.getBranch()].find((header) => field === header.name)) {
            delete totals[field]
        }
    })

    return totals
}

function calculateCostCentreSums(workhours) {

    const costSet = new Set()    

    //Collect unique costCentres
    workhours.forEach((wh) => {
        costSet.add(wh.costCentre)
    })

    const costCentres = []

    //Iterate costCentres, filter and calculate total with each centre
    Array.from(costSet).forEach((cc) => {
        const ccwhs = workhours.filter((wh) => wh.costCentre === cc)
        costCentres.push({
            costCentre: cc,
            ...calculateUserTotal(ccwhs),
            bankKeep: ccwhs.filter((row) => row.bankMode === 1).length,
            bankUse: ccwhs.filter((row) => row.bankMode === 2).length
        })
    })

    return costCentres
}

function calculatePayroll(workhours) {

    const total = calculateUserTotal(workhours)

    const payrollHours = {
        workHours: total.workHours,
        eveningHours: total.eveningHours,
        nightHours: total.nightHours,
        shortTime: total.shortTime,
        sickHours: total.sickHours,
        holidayHours: total.holidayHours,
        sundayHours: total.sundayHours,
        overworktotal: 0,
        overworkhalf: 0,
        overworkfull: 0,
        toBank: 0,
        dailyAllowanceHalf: 0,
        dailyAllowance: 0
    }

    workhours.forEach((row) => {
        if (row.dailyAllowance === 1) {
            payrollHours.dailyAllowanceHalf++
        }
        else if (row.dailyAllowance === 2) {
            payrollHours.dailyAllowance++
        }
    })

    if (total.workHours > 80) {
        payrollHours.overworktotal = total.workHours - 80

        const leftover = total.workHours - 80

        payrollHours.workHours = 80

        if (leftover > 12) {
            payrollHours.overworkhalf = 12
            payrollHours.overworkfull = leftover - 12
            payrollHours.toBank = payrollHours.overworkfull
        } else if (leftover > 0) {
            payrollHours.overworkhalf = leftover
        }

    }

    return payrollHours
}

function UserReportPayroll({ workhours }) {

    const payroll = calculatePayroll(workhours)

    const payHeaders = payheadersAll[Config.getBranch()]

    return (
        <TableContainer sx={{ width: 200 }}>
            <Typography variant='h6'>Yhteenveto: Palkka</Typography>
            <Table>
                <TableHead>
                    <TableRow>
                        <TableCell>Palkkalaji</TableCell>
                        <TableCell>Tunnit</TableCell>
                    </TableRow>
                </TableHead>
                <TableBody>
                    {payHeaders.map((field, key) => (
                        <TableRow key={key}>
                            <TableCell>{field.header}</TableCell>
                            <TableCell>{field.get ? field.get(payroll[field.field]) : getTime(payroll[field.field])}</TableCell>
                        </TableRow>
                    ))}
                </TableBody>
            </Table>
        </TableContainer>
    )
}

function UserReportCostSum({ workhours }) {

    const centreSums = calculateCostCentreSums(workhours)
    const headersFilter = costCentreHeadersAll[Config.getBranch()].filter((header) => Object.keys(centreSums[0]).includes(header.name))

    return (
        <TableContainer>
            <Typography variant='h6'>Yhteenveto: Tunnit tulospaikoittain</Typography>
            <Table>
                <TableHead>
                    <TableRow>
                        {headersFilter.map((field, key) => (
                            <TableCell key={key}>{field.header}</TableCell>
                        ))}
                    </TableRow>
                </TableHead>
                <TableBody>
                    {centreSums.map((centre, key) => (
                        <UserReportTimeRow key={key} workhours={centre} headerList={headersFilter} />
                    ))}
                </TableBody>
            </Table>
        </TableContainer>
    )
}

function UserReportTimeRow({ workhours, headerList }) {

    return (
        <TableRow hover={true}>
            {headerList.map((field, key) => (
                <TableCell key={key}>
                    {field.get ? field.get(workhours[field.name]) : workhours[field.name]}
                </TableCell>
            ))}
        </TableRow>
    )
}

function UserReportPage({ user, timeFrame }) {

    const total = calculateUserTotal(user.workhours)

    return (
        <Box>
            <Typography variant="h5">{user.user} {user.name}</Typography>
            <Typography variant='h6'>Palkkajakso: {format(timeFrame.from, "dd.MM.yyyy")} - {format(timeFrame.to, "dd.MM.yyyy")}</Typography>
            <Table stickyHeader={true}>
                <TableHead>
                    <TableRow>
                        {headersAll[Config.getBranch()].map((field, key) => (
                            <TableCell key={key}>{field.header}</TableCell>
                        ))}
                    </TableRow>
                </TableHead>
                <TableBody>
                    {user.workhours.map((wh, key) => (
                        <UserReportTimeRow key={key} workhours={wh} headerList={headersAll[Config.getBranch()]} />
                    ))}
                </TableBody>
                <TableFooter>
                    <TableRow>
                        <TableCell></TableCell>
                        <TableCell></TableCell>
                        <TableCell></TableCell>
                        <TableCell sx={{ fontWeight: "bold", fontSize: "0.9rem" }}>                            
                                Vähennys:
                        </TableCell>
                        <Tooltip slotProps={{
                            popper: {
                                modifiers: [
                                    { name: 'offset', options: { offset: [0, -40] } }
                                ]
                            }
                        }} 
                        title={`Työtunnit (${getTime(total.workHours + total.breakHours + total.waitHours)}) - (Tauot - Odotus)`} 
                            placement="right" 
                            arrow
                        >
                        <TableCell sx={{ fontWeight: "bold", fontSize: "0.9rem", width: "auto"}}>
                            -{getTime(total.breakHours + total.waitHours)}
                        </TableCell>
                        </Tooltip>
                        {Object.keys(total).map((field, key) => (
                            <TableCell key={key}></TableCell>
                        ))}
                        <TableCell></TableCell>
                        <TableCell></TableCell>                        
                    </TableRow>
                    <TableRow>
                        <TableCell></TableCell>
                        <TableCell></TableCell>
                        <TableCell></TableCell>
                        <TableCell></TableCell>
                        {Object.keys(total).map((field, key) => (
                            <TableCell sx={{ fontWeight: "bold" }} key={key}>{getTime(total[field])}</TableCell>
                        ))}
                        <TableCell></TableCell>
                        <TableCell></TableCell>
                        <TableCell></TableCell>                        
                    </TableRow>
                </TableFooter>
            </Table>
            <UserReportCostSum workhours={user.workhours} />
            <UserReportPayroll workhours={user.workhours} />
        </Box>
    )
}

export function WorkHourReport() {

    const [timeFrame, setTimeFrame] = useState(getPayTimeframe());

    const driver = LogiTarUser.current.isDriver()

    const [workHours, setWorkHours] = useState()

    const [select, setSelect] = useState()

    const [downloadURL, setDownloadURL] = useState()

    //Memoized timeframe for keeping the timeframe in report correct
    const fetchedTime = useMemo(() => {
        return { ...timeFrame }
    }, [workHours])

    const handleButton = (b) => {

        if (!b) {
            return setTimeFrame(prev => ({ from: subDays(prev.from, step), to: subDays(prev.to, step) }))
        }
        setTimeFrame(prev => ({ from: addDays(prev.from, step), to: addDays(prev.to, step) }))
    }

    const getPay = () => {
        setSelect()
        setWorkHours()
        LogitarApi.getWorkHours(timeFrame.from, timeFrame.to, driver ? LogiTarUser.current.info.id : null)
            .then((r) => {

                if (r.workhours.length < 1) {
                    setWorkHours([])
                } else {

                    let url = APIPath +
                        "/getworkhours.php?key=" + LogitarApi.getCookie("login-token") +
                        "&format=csv&from=" + format(timeFrame.from, "yyyy-MM-dd") +
                        "&to=" + format(timeFrame.to, "yyyy-MM-dd")

                    if (driver) url += `&user=${LogiTarUser.current.info.id}`
                    setDownloadURL(url);
                    setWorkHours(() => r.workhours)
                }

            })
            .catch((e) => { })
    }

    return (
        <Box sx={{ ...contentBoxStyle, overflow: "auto" }}>
            <ThemeProvider theme={(theme) => createTheme({ ...theme, components: { ...theme.components, ...tableTheme.components } })}>
                <FormGroup column="true" sx={{ px: 1 }}>
                    <FormGroup row sx={{
                        '& .MuiButton-root': {
                            height: 35
                        },
                        mt: 1,
                        display: 'flex',
                        flexDirection: 'row',
                        flexWrap: 'wrap',
                        gap: 1
                    }}>
                        <DatePick disabled={true} date={timeFrame} buttonSet={handleButton} dateSet={setTimeFrame} />
                        <TipButton title="Hae palkkatiedot" variant="contained" icon={Search} onClick={getPay}></TipButton>
                        {
                            !driver &&
                            <>
                                {enableSalaryAndHolidayFiles && (
                                <>
                                    <Button href={downloadURL + "&filetype=hours"} disabled={downloadURL === undefined} variant="contained">Palkkojen siirtotiedosto</Button>
                                    <Button href={downloadURL + "&filetype=holiday"} disabled={downloadURL === undefined} variant="contained">Lomien siirtotiedosto</Button>
                                </>
                                )}
                                <Button href={downloadURL + "&filetype=away"} disabled={downloadURL === undefined} variant="contained">Poissaolojen siirtotiedosto</Button>
                            </>
                        }

                    </FormGroup>
                </FormGroup>

                <FormGroup sx={{ overflow: "auto" }}>
                    {workHours === undefined ?
                        <></>
                        : workHours.length > 0
                            ? <Paper elevation={2}>
                                {!driver ?
                                    <>
                                        <ToggleButtonGroup
                                            exclusive
                                            value={select}
                                            onChange={(e) => {
                                                setSelect(e.target.value)
                                            }}
                                            sx={{ flexWrap: "wrap" }}
                                        >
                                            {workHours.map((user, key) => (
                                                <ToggleButton key={key} sx={select == key ? { bgcolor: 'primary.main', color: "white", ":hover": { bgcolor: "primary.dark" } } : { color: "text.primary" }} value={key}>{user.user} {user.name}</ToggleButton>
                                            ))}
                                        </ToggleButtonGroup>
                                        {select &&
                                            <UserReportPage user={workHours[select]} timeFrame={fetchedTime} />
                                        }


                                    </>
                                    : <UserReportPage user={workHours[0]} timeFrame={fetchedTime} />}
                            </Paper>
                            : <Typography variant='h5'>Aikajaksolla ei kirjattuja tunteja</Typography>
                    }
                </FormGroup>
            </ThemeProvider>
        </Box>
    )
}